export const PRODUCTION = 'production'
export const DEVELOPMENT = 'development'

export const getEnvironment = () => {
  if (typeof window === 'undefined') return PRODUCTION

  const hostname = window.location.hostname
  const query = new URLSearchParams(window.location.search)
  const localStorageRecord = localStorage.getItem('env')

  if (query.get('env') === DEVELOPMENT || localStorageRecord === DEVELOPMENT) return DEVELOPMENT
  if (query.get('env') === PRODUCTION || localStorageRecord === PRODUCTION) return PRODUCTION

  switch (hostname) {
    //case 'localhost':
    case 'dev.decormatters.com':
      return DEVELOPMENT
    default:
      return PRODUCTION
  }
}

export const IS_PRODUCTION = getEnvironment() === PRODUCTION
export const IS_DEVELOPMENT = getEnvironment() === DEVELOPMENT

export const DEV_BASE = IS_PRODUCTION ? `https://server.decormatters.com/parse/functions/` : `https://decormatters-dev.herokuapp.com/parse/functions/`
export const BASE_SHARE_URL = IS_PRODUCTION ? `https://www.decor.app/` : 'https://dev.decor.app'
export const APPID = IS_PRODUCTION ? `3` : `1`
export const APP_VERSION = 'w 1.3.3'
export const APP_CACHE = 1
export const ASSET_URL = 'https://didr9pubr8qfh.cloudfront.net/'
export const GET_WISHLIST = `${DEV_BASE}getSavedProductsAtlas1`
export const GET_BOOKMARKS = `${DEV_BASE}getAllSavedIds2`
export const GET_USER_INSPIRATIONS = `${DEV_BASE}getUserInspirations5`
export const GET_TEMPLATES = `${DEV_BASE}getTemplates2`
export const GET_USER_INSPIRATION = `${DEV_BASE}getUserInspiration1`
export const GET_USER_RECOMMENDATION_INSPIRATION = `${DEV_BASE}getDesignRecommendations1`
export const GET_ALL_SAVED_IDS = `${DEV_BASE}getAllSavedIds1`
export const GET_ALL_SAVED_INSPITATIONS = `${DEV_BASE}getSavedUserInspirations1`
export const GET_PRODUCTS = `${DEV_BASE}fetchProductsWithIds1`
export const SAVE_USER_INSPIRATION = ` ${DEV_BASE}saveUserInspirations1`
export const ARCHIVE_USER_INSPIRATION = ` ${DEV_BASE}archiveSavedUserInspirations1`
export const GET_ALL_FOLLOWING_IDS = `${DEV_BASE}getAllFollowingIds1`
export const GET_FOLLOWERS = `${DEV_BASE}getFollowers1`
export const GET_FOLLOWINGS = `${DEV_BASE}getFollowings2`
export const FOLLOW_USER = `${DEV_BASE}followUser2`
export const UNFOLLOW_USER = `${DEV_BASE}unfollowUser1`
export const LIKE_INSPIRATION = `${DEV_BASE}likeUserInspiration2`
export const UNLIKE_INSPIRATION = `${DEV_BASE}unlikeUserInspiration1`
export const GET_ME2 = `${DEV_BASE}getMe1`
export const GET_ALL_SAVED_PRODUCTS2 = `${DEV_BASE}getAllSavedProducts1`
export const SAVE_PRODUCT2 = `${DEV_BASE}createSavedProducts1`
export const DELETE_PRODUCTS = `${DEV_BASE}deleteSavedProducts2`
export const DELETE_DRAFT = `${DEV_BASE}deleteIdeas2`
export const DELETE_SAVED_PRODUCT2 = `${DEV_BASE}deleteSavedProducts1`
export const GET_ALL_FOLLOWINGS = `${DEV_BASE}getFollowings2`
export const UPDATE_USERNAME = `${DEV_BASE}updateUserUniqueDisplayName1`
export const SEARCH_INSPIRATION = `${DEV_BASE}getUserInspirationsWithText2`
export const SEARCH_USER = `${DEV_BASE}getUsers1`
export const GET_CMNTS = `${DEV_BASE}getUserInspirationComments2`
export const POST_COMMENT = `${DEV_BASE}createUserInspirationComment3`
export const LOGIN_WITH_EMAIL = `${DEV_BASE}logInWithEmail1`
export const LOGOUT = `${DEV_BASE}logOut`
export const REQUEST_PASSWORD_RESET = `${DEV_BASE}requestPasswordReset1`
export const VERIFY = `${DEV_BASE}updateUser1`
export const RESEND_VERIFICATION_EMAIL = `${DEV_BASE}sendVerificationEmail1`
export const REGISTER_WITH_EMAIL2 = `${DEV_BASE}signUpWithEmail2`
export const GET_STORE_NAMES = `${DEV_BASE}getStores1`
export const LINK_USER = `${DEV_BASE}linkUser1`
export const GET_DAILY_TASKS = `${DEV_BASE}getDailyTasks12`
export const ATTEMPT_DAILY_TASK_RESET = `${DEV_BASE}attemptDailyTaskReset5`
export const REWARD_DAILY_TASK = `${DEV_BASE}rewardDailyTask1`
export const GET_WELCOME_GIFTS = `${DEV_BASE}getWelcomeGifts1`
export const WELCOME_GIFT_STATUS = `${DEV_BASE}welcomeGiftStatus1`
export const GET_NOTIFICATIONS = `${DEV_BASE}getNotifications7`
export const GET_REWARD_NOTIFICATIONS = `${DEV_BASE}getRewardNotifs3`
export const GET_WEEKLY_REPORTS = `${DEV_BASE}getWeeklyReports1`
export const NOTIFICATIONS_RESET = `${DEV_BASE}resetNumUnseenNotifications2`
export const NOTIFICATIONS_MARK_ALL_READ = `${DEV_BASE}markAllNotificationsRead1`
export const SEND_COMPLAINT = `${DEV_BASE}createComplaint1`
export const LIKE_COMMENT = `${DEV_BASE}likeUserInspirationComment1`
export const UNLIKE_COMMENT = `${DEV_BASE}unlikeUserInspirationComment1`
export const SOCIAL_MEDIA_SHARE_URL = `${BASE_SHARE_URL}design/`
export const SOCIAL_MEDIA_BLOG_URL = `${BASE_SHARE_URL}`
export const TWITTER_TITLE = `Check out this design`
export const FB_LOGIN_APP_ID = `722167891268362`
export const SERVER_URL = `https://server.decormatters.com/parse`
export const AWS_REGION = `us-east-1`
export const AWS_COGNITO_REGION = `us-east-1`
export const AWS_COGNITO_IDENTITY_POOL_ID = `us-east-1:650acaca-43ef-4bef-a103-365e2ca68143`
export const AWS_USER_POOLS_ID = `us-east-1_WxSYm1Dse`
export const AWS_USERPOOLS_WEB_CLIENT_ID = `6qvd7odtr4dvqlmr35acemjvqt`
export const AWS_FEDERATION_TARGET = `COGNITO_IDENTITY_POOLS`
export const AWS_CONTENT_BUCKET = `decormatters-hosting-dev`
export const AWS_CONTENT_REGION = `us-east-1`
export const AWS_CONTENT_URL = `https://d1tla8fk8dyqd6.cloudfront.net`
export const GRAPHQL_URI = `https://m6fau5g6gc.execute-api.us-east-1.amazonaws.com/dev/graphql`
export const GRAPHQL_API_KEY = `KyVrh68pKG2Tm0yNRaUDv14Rh607s2rt9bbSQmqV`
export const LAMBA_BASE_URL = `https://rfe0tf2er6.execute-api.us-east-1.amazonaws.com/Prod/`
export const AUTH_URL = `${LAMBA_BASE_URL}auth`
export const USER_URL = `${LAMBA_BASE_URL}check`
export const BRANCH_KEY = `key_live_idtIzj22O8VpDiPOaEyjOckcuxgHXwOt`
export const NOTIFICATION_ENABLED = `1`
export const PRICE_TOOLTIP_ENABLED = `0`
export const PRICE_ENABLED = `0`
export const SMS_COUNTRIES = `us`
export const GET_SEARCH_RECOMMENDATION = `${DEV_BASE}getHomeSearchSuggestions2`
export const GET_HASHTAGS = `${DEV_BASE}getPopularHashtags1`
export const ENABLE_POPULAR_HASHTAGS = `0`
export const ENABLE_RECENT_SEARCHES = `1`
export const GTAG_ID = `GTM-NDH37RD`
export const LOGIN_REDIRECT_URL = `/login`
export const LOGIN_VALID_DOMAINS = `decormatters.com`
export const DESIGNER_URL = `https://decormatters.com`
export const DESIGNER_ENABLE = `1`
export const SAMPLE_LOGIN_ENABLED = `1`
export const GOOGLE_CLIENT_ID = `960415879026-52v0po50sd9n2rbme63bcbnpmash159l.apps.googleusercontent.com`
export const GET_PROFILE_BY_ID = `${DEV_BASE}getUser1`
export const ENABLE_USER_PROFILE = `0`
export const STRIPE_ENDPOINT = `https://api.stripe.com/v1`
export const GET_COINS_HISTORY = `${DEV_BASE}getCoinTransactions1`
export const GET_LEADER_BOARD = `${DEV_BASE}getLeaderboardHome4`
export const GET_LEADER_BOARD_CATEGORY = `${DEV_BASE}getLeaders1`
export const GET_RECOMMENDED_USERS = `${DEV_BASE}getRecommendedUsers1`
export const REWARD_WELCOME_GIFT = `${DEV_BASE}rewardWelcomeGift1`
export const GET_FUN_AVATARS = `${DEV_BASE}getFunAvatars1`
export const GET_FUN_AVATARS_PURCHASES = `${DEV_BASE}getFunAvatarPurchases1`
export const SELECT_FUN_AVATAR = `${DEV_BASE}selectFunAvatar1`
export const BUY_FUN_AVATAR = `${DEV_BASE}buyFunAvatar1`

// BLOG
export const BLOG_API_URL = `https://decormatters-blog.herokuapp.com`
export const GET_BLOG_POSTS = `${BLOG_API_URL}/api/posts`
export const GET_POST_FEATURE_INFO = `${BLOG_API_URL}/api/blog-feature-line`
export const GET_BLOG_CATEGORIES = `${BLOG_API_URL}/api/categories`
export const GET_BLOG_HOMEPAGE_METATAGS = `${BLOG_API_URL}/api/homepage`
export const GET_DESIGN_CONTEST = `${DEV_BASE}getDesignContests5`
export const GET_USER_EVENTS = `${DEV_BASE}getUserEventsPlayTab1`
export const GET_USER_EVENTS_ALL = `${DEV_BASE}getUserEvents2`
export const GET_ALL_PURCHASES = `${DEV_BASE}getAllMpPurchaseRecords1`
export const DO_PURCHASE = `${DEV_BASE}buyMarketProducts1`
export const DO_PURCHASE_MYDECOR = `${DEV_BASE}buyUserProducts1`
export const DO_PURCHASE_ITEM = `${DEV_BASE}buyItems3`
export const GET_CHALLENGES = `${DEV_BASE}getDesignContests5`
export const GET_JOIN_CHALLENGE = `${DEV_BASE}didUserJoinChallenge1`
export const GET_DID_JOIN_USER_EVENT = `${DEV_BASE}didUserJoinUserEvent1`
export const GET_IDEA = `${DEV_BASE}getIdea1`
export const GET_USER_TEMPLATES = `${DEV_BASE}getUserTemplates1`
export const GET_INSPIRATIONS = `${DEV_BASE}getUserInspirations5`
export const GET_SIGNED_URL = `${DEV_BASE}getSignedUrl1`
export const CREATE_TEMPLATE = `${DEV_BASE}createUserTemplate1`
export const SAVE_DRAFT = `${DEV_BASE}createIdea8`
export const SAVE_POST = `${DEV_BASE}createUserInspiration6`
export const DELETE_TEMPLATES = `${DEV_BASE}archiveUserTemplates1`
export const DELETE_POST = `${DEV_BASE}deleteUserInspirations1`
export const UPDATE_POST = `${DEV_BASE}updateUserInspiration1`
export const GET_PURCHASE_ITEMS = `${DEV_BASE}getMarketplacePurchases2`
export const GET_ITEMS = `${DEV_BASE}getItems2`
export const GET_HASH_TAGS = `${DEV_BASE}getHashtags1`

export const GET_DESIGN_GAMES = `${DEV_BASE}getPlayTab1`

export const GET_OTHER_USER = `${DEV_BASE}getOtherUser1`

export const GET_USER_HOSTED_EVENT = `${DEV_BASE}getUserEvents2`
export const SAVE_USER_HOSTED_EVENT = `${DEV_BASE}saveUserEvent1`
export const GET_MY_USER_EVENTS = `${DEV_BASE}getUserEventsCreateTab1`
export const ARCHIVE_EVENT = `${DEV_BASE}archiveUserEvent1`

export const UPLOAD_IMAGE = `${DEV_BASE}uploadImage1`

export const UNIQUE_NAME_LOOKUP = `${DEV_BASE}uniqueDisplayNameLookup1`

// VOTING
export const GET_VOTING_POSTS = `${DEV_BASE}getRandomDesigns1`
export const VOTE_FOR_POST = `${DEV_BASE}voteDesign1`

export const GET_COMMENT_LIKE_STATUSES = `${DEV_BASE}getCommentLikeStatuses1`
