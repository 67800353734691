import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import notificationImg from '../../assets/Notification.svg'
import searchImg from '../../assets/Search.svg'
import { ReactComponent as CalendarIcon } from '../../assets/dailyRewards/calendar.svg'
import mobileLogoImg from '../../assets/dm-mobile.svg'
import menuIcon from '../../assets/menu.svg'
import backArrowImg from '../../assets/navbar/back.svg'
import { useCurrentRoute } from '../../hooks/useCurrentRoute'
import useDailyTasks from '../../hooks/useDailyTasks'
import { useHeaderOptions } from '../../hooks/useHeaderOptions'
import { useSecondaryMenu } from '../../hooks/useSecondaryMenu'
import { gotoLogIn } from '../../redux/actions/AuthActions'
import {
  closeModal
  //resetAndOpen
} from '../../redux/communityReducers/CheckInReducer'
import { NAV_WORKSPACE } from '../../redux/constants'
import { logEvent } from '../../utils/firebase'
import { getUserAvatar } from '../../utils/getUserAvatar'
import MobileDrawerMenu from '../MobileDrawerMenu'
import MobileSearchPopup from '../MobileSearchPopup'
import NotificationsMenu from '../NotificationsMenu'
import ProfilePopupMenu from '../ProfilePopupMenu'
import {
  AvatarImage,
  DailyRewardsButton,
  HeaderButton,
  HeaderCommunityMenu,
  HeaderLeftPart,
  HeaderMenuLink,
  HeaderRightPart,
  HeaderRoot,
  HeaderSearchWrapper,
  HeaderWrapper,
  LoginButton,
  SearchIcon,
  SearchInput,
  SignUpButton
} from './MobileHeader.styles'

const MobileHeader = () => {
  const { openDailyTask } = useDailyTasks()
  const [showNotifications, setShowNotifications] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [showUserMenu, setShowUserMenu] = useState(false)
  const [isSearchMode, setIsSearchMode] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const { backButton, disableHeaderShadow } = useHeaderOptions()
  const [showBackButton, setShowBackButton] = useState(backButton || false)
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const me = useSelector(state => state.profile.me)
  const user = useSelector(state => state.profile.me)
  const navbar = useSelector(state => state.designer.navbar)
  const dispatch = useDispatch()
  const secondaryMenu = useSecondaryMenu()
  const { browserPath } = useCurrentRoute()
  const router = useRouter()

  useEffect(() => {
    if (['/templates', '/challenges', '/drafts', '/mydecor', '/myrooms', '/design/', '/myevents', '/event', '/leaders/', '/settings'].some(path => browserPath.includes(path))) {
      setShowBackButton(true)
    } else {
      setShowBackButton(false)
    }

    if (backButton) {
      setShowBackButton(backButton)
    }
  }, [browserPath, backButton])

  if (navbar === NAV_WORKSPACE) return null

  const toggleNotificationsMenu = () => {
    setShowNotifications(!showNotifications)
    setShowDrawer(false)
    setShowUserMenu(false)
    dispatch(closeModal())
  }

  const handleSubmit = e => {
    e.preventDefault()
  }

  const handleLogIn = () => {
    logEvent('nav_mobile_login_clicked', {})
    dispatch(gotoLogIn())
  }

  const handleDownload = () => {
    logEvent('download_mobile_header_click', {})
    window.open('https://decormatters.app.link/WTHVetOFCMb')
  }

  if (router.query.tutorial) return null

  return (
    <>
      <HeaderRoot disableShadow={disableHeaderShadow}>
        <HeaderWrapper>
          <HeaderLeftPart>
            {!showBackButton && (
              <>
                <img
                  onClick={() => {
                    setShowDrawer(true)
                    setShowUserMenu(false)
                    setShowNotifications(false)
                    setIsSearchMode(false)
                    dispatch(closeModal())
                  }}
                  src={menuIcon}
                  alt="menu"
                  width={24}
                  height={18}
                  style={{ marginLeft: 16 }}
                />
                <Link href="/">
                  <img src={mobileLogoImg} width={24} height={24} alt="DecorMatters" style={{ marginLeft: 14 }} />
                </Link>
              </>
            )}
            {showBackButton && <img width={36} height={36} src={backArrowImg} alt="Back" onClick={() => history.back()} />}
          </HeaderLeftPart>
          {!isSearchMode && (
            <HeaderRightPart>
              {user && (
                <HeaderButton aria-label="Search" onClick={() => setIsSearchMode(true)}>
                  <img width={36} height={36} src={searchImg} alt="" />
                </HeaderButton>
              )}
              {!user && (
                <>
                  <LoginButton onClick={handleLogIn}>Log In</LoginButton>
                  <SignUpButton onClick={handleDownload}>Download</SignUpButton>
                </>
              )}
              {user && (
                <>
                  <HeaderButton onClick={toggleNotificationsMenu}>
                    <img height="30px" src={notificationImg} alt="" />
                  </HeaderButton>
                  <DailyRewardsButton
                    onClick={async () => {
                      await openDailyTask()
                      //dispatch(resetAndOpen())
                      setShowUserMenu(false)
                    }}
                  >
                    <CalendarIcon style={{ width: 18, height: 17, flexShrink: 0 }} />
                  </DailyRewardsButton>
                  <AvatarImage
                    onClick={() => {
                      setShowUserMenu(!showUserMenu)
                      setShowNotifications(false)
                      dispatch(closeModal())
                    }}
                    active={showUserMenu}
                    src={getUserAvatar(me)}
                  />
                </>
              )}
            </HeaderRightPart>
          )}
          {isSearchMode && (
            <HeaderSearchWrapper onSubmit={handleSubmit} action="/search" as="form">
              <SearchInput name="q" autoFocus={true} value={searchQuery} onChange={e => setSearchQuery(e.target.value)} />
              <SearchIcon src={searchImg} alt="" />
            </HeaderSearchWrapper>
          )}
        </HeaderWrapper>
        {!isSearchMode && secondaryMenu.length > 0 && isAuthenticated && (
          <HeaderCommunityMenu>
            {secondaryMenu.map(link => {
              if (!link.visible) return null

              return (
                <Link href={link.path} passHref>
                  <HeaderMenuLink active={link.active}>{link.title}</HeaderMenuLink>
                </Link>
              )
            })}
          </HeaderCommunityMenu>
        )}
      </HeaderRoot>
      {showDrawer && <MobileDrawerMenu isAuthenticated={!!user} onClose={() => setShowDrawer(false)} showNotifications={toggleNotificationsMenu} />}
      {user && (
        <>
          {showNotifications && <NotificationsMenu close={toggleNotificationsMenu} />}
          {showUserMenu && <ProfilePopupMenu onClose={() => setShowUserMenu(false)} />}
        </>
      )}
      {isSearchMode && <MobileSearchPopup onClose={() => setIsSearchMode(false)} searchQuery={searchQuery} />}
    </>
  )
}

export default MobileHeader
