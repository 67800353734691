export const FEED_HARD_RESET = 'feed_init'
export const FEED_RESET = 'feed_reset'

export const DEV_ENDPOINT = 'https://decormatters-dev.herokuapp.com/parse/functions/'
export const PROD_ENDPOINT = 'https://server.decormatters.com/parse/functions/'

export const NAV = 'nav'
export const NAV_WORKSPACE = 'nav_workspace'
export const NAV_POST = 'nav_post'

export const EDIT_MODE_MOVE = 'edit_mode_move'
export const EDIT_MODE_PERSPECTIVE = 'edit_mode_perspective'
export const EDIT_MODE_PERSPECTIVE_VERT = 'edit_mode_perspective_vert'
export const EDIT_MODE_PERSPECTIVE_HOR = 'edit_mode_perspective_hor'
export const EDIT_MODE_CROP = 'edit_mode_crop'

export const ARRANGE_BACK = 'arrange_back'
export const ARRANGE_FRONT = 'arrange_front'

export const FLIP_X = 'flip_x'
export const FLIP_Y = 'flip_y'

export const ROTATE_CLOCK_45 = 'rotate_45'
export const ROTATE_COUNTER_45 = 'rotate_-45'
export const ROTATE_RESET = 'rotate_reset'

export const TOOL_BRIGHTNESS = 'tool_bright'

export const PRINT = 'print'

export const MENU_PRODUCT = 'menu_product'
export const MENU_WISH = 'menu_wish'
export const MENU_MY = 'menu_my'
export const MENU_EFFECT = 'menu_effect'
export const MENU_INVENTORY = 'menu_inv'

export const MENU_0 = 5
export const MENU_1 = 180
export const MENU_2 = 330
export const MENU_SNAP_WIDTH = [0, 180, 330]
export const MENU_SNAP_HEIGHT = [0, 40]

export const ITEMS_SHOP = 'items_shop'
export const ITEMS_FREE = 'items_free'
export const ITEMS_FILTER = 'items_filter'

export const FEED_INIT = 'feed_init'

export const LOAD_PENDING = 'pending'
export const LOAD_COMPLETE = 'idle'

export const STATIC_COIN_AMOUNT = 300

export const TRAY_OPEN_WIDTH = 330
export const TRAY_OPEN_HEIGHT_PERCENTAGE = 40
