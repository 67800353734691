import qs from 'qs'
import { createStripeAction } from '../../utils/stripeUtils'

export const searchStripeAction = createStripeAction('search', 'GET', '')
export const cancelStripeSubscriptionAction = createStripeAction('subscriptions', 'POST')

export const getCustomerSubscriptionsByQuery = async email => {
  const queryParams = qs.stringify({ query: email })
  const searchResults = await searchStripeAction(`?${queryParams}&count=100`)

  if (!searchResults.data || searchResults.data.length === 0) {
    throw new Error('No data')
  }

  const customer = searchResults.data.find(item => item.object === 'customer' && item?.subscriptions?.data?.length > 0)

  if (!customer || !customer.subscriptions || !customer.subscriptions.data || customer.subscriptions.data.length === 0) {
    throw new Error('No data')
  }

  return customer.subscriptions.data
}

export const cancelSubscriptionForEmailAction = async email => {
  const subscriptions = await getCustomerSubscriptionsByQuery(email)

  const cancellations = subscriptions.map(subscription => cancelStripeSubscriptionAction(`?subscriptionId=${subscription.id}&cancel_at_period_end=true`))

  return Promise.all(cancellations)
}
